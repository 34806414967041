@import "_variables.scss";
.doc-share {
  a :hover {
    text-decoration: underline;
  }
  .doc-share-img {
    height: 24px;
    width: 24px;
  }
  .doc-share-text {
    font-weight: var(--chakra-fontWeights-bold);;
    color: $color-link-hover-color;
    &:hover {
      color: $color-btn-link-hover;
    }
  }
}

.doc-page-header {
  font-size: $font-size-reg;
  font-weight: var(--chakra-fontWeights-normal);
  line-height: 24px;
  color: $color-font-card;
  .action-button {
    color: $color-link-hover-color;
    &:hover{
      color: $color-btn-link-hover
    }
  }
}
